import React from 'react';
import Hero from '../Hero';
import Section, { SubSection } from '../Section';
import SmallLink from '../SmallLink';
import { VideoBlank } from '../Home/Video';

export const G4TKContent = ({ hero, blurbs, info, submit, video }) => {
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <div className="flex flex-col w-full justify-center items-start space-y-6">
          {blurbs.map((item, index) => (
            <p
              key={index}
              className="text-2xl font-baloo text-s4tk-blue text-left sm:text-justify"
            >
              {item.text}
            </p>
          ))}
        </div>
        <SubSection>
          <p className="text-3xl font-baloo text-s4tk-blue font-bold mb-4">
            {info.title}
          </p>
          <div className="w-full flex flex-col justify-center items-start space-y-6">
            {info.items.map((item, index) => (
              <div>
                <p
                  key={index}
                  className="text-2xl font-baloo text-s4tk-blue text-left mb-2"
                >
                  {item.title}
                </p>
                <div className="w-full flex flex-col justify-center items-start space-y-4 px-0 md:px-4 lg:px-8">
                  {item.blurbs.map((blurb, bIndex) => (
                    <p
                      key={bIndex}
                      className="text-lg text-black font-baloo text-left sm:text-justify"
                    >
                      {blurb.text}
                    </p>
                  ))}
                </div>
                {item.links && (
                  <div className="w-full flex flex-row flex-wrap justify-center items-center mt-4">
                    {item.links.map((link, lIndex) => (
                      <SmallLink key={lIndex} item={link} />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </SubSection>
        <SubSection>
          <div className="w-full flex items-center justify-center mb-4">
            <SmallLink item={submit.item} />
          </div>
          <p className="text-left sm:text-justify text-black font-baloo text-xl">
            {submit.blurb}
          </p>
        </SubSection>
        <SubSection>
          <VideoBlank videoInfo={video} />
        </SubSection>
      </Section>
    </>
  );
};

export default G4TKContent;
