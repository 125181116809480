import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import G4TKContent from '../components/Programs/G4TKContent';

export const G4TKPageTemplate = ({
  title,
  hero,
  blurbs,
  info,
  submit,
  video
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <G4TKContent
        hero={hero}
        blurbs={blurbs}
        info={info}
        submit={submit}
        video={video}
      />
    </div>
  );
};

const G4TKPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <G4TKPageTemplate
        title={frontmatter.title}
        hero={frontmatter.hero}
        blurbs={frontmatter.blurbs}
        info={frontmatter.info}
        submit={frontmatter.submit}
        video={frontmatter.video}
      />
    </Layout>
  );
};

export default G4TKPage;

export const g4tkPageQuery = graphql`
  query G4TKPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "g4tk-page" } }) {
      frontmatter {
        title
        hero {
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        blurbs {
          text
        }
        info {
          title
          items {
            title
            blurbs {
              text
            }
            links {
              title
              icon {
                prefix
                title
              }
              url
            }
          }
        }
        submit {
          item {
            title
            icon {
              prefix
              title
            }
            url
          }
          blurb
        }
        video {
          url
        }
      }
    }
  }
`;
